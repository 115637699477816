// 파일 경로: src/pages/MyPage.tsx
import React from "react";

const MySetting: React.FC = () => {
  return (
    <div className="container mx-auto mt-10 p-4">
      <h1>안녕</h1>
    </div>
  );
};

export default MySetting;
